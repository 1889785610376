// pages/About.js
//import { useAppContext } from '../context/AppContext';
import TextArea from '../components/TextArea';
import ShowDate from '../components/ShowDate';

export default function About() {
  //const { sharedMessage, counter, incrementCounter } = useAppContext();
  
  return (
    <TextArea>
      <h1 className="text-4xl font-bold mb-6 text-[#fff444]" style={{ fontFamily: 'Belanosima', fontWeight: '400' }}>About YAGA</h1>
      <p className="text-2xl text-gray-100 px-6">
        Yet Another Game Awards (YAGA) was created in 2024 by gamer and twitch streamer, Bri Moore (BlondePizza).
        <br></br><br></br>
        YAGA was created to recognize and celebrate outstanding games and developers, when other awards shows might fail to do so.
        <br></br><br></br>
        We strive to spotlight indie developers and lesser-known games that might not get the recognition they deserve.
        <br></br><br></br>
        YAGA is a community-driven awards show - where the audience has a say in who wins.
        <br></br><br></br>
        Our core values are Community, Artistry, and Authenticity. 
        <br></br>
        Our goal is to create a platform where all games can be judged equally by their quality and innovation, not the size of their budgets.
        <br></br><br></br>
        Thank you for joining us in celebrating games!
      </p>
      <br></br><br></br>
      {/* <ShowDate /> */}
    </TextArea>
  );
}