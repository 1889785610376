import { Link } from 'react-router-dom';

export default function Navigation() {
  return (
    <nav className="bg-[#000069] shadow-md p-4" style={{"user-select": "none"}}>
      <div className="max-w-4xl mx-auto flex gap-4 justify-center xl:space-x-32 sm:space-x-4 text-white xl:text-2xl sm:text-lg" style={{ fontFamily: 'Belanosima', fontWeight: '400', letterSpacing: '.15rem'}}>
        <Link to="/" className="hover:text-[#fff444] active:text-yellow-600">HOME</Link>
		<Link to="/winners" className="hover:text-[#fff444] active:text-yellow-600">WINNERS</Link>
        <Link to="/nominees" className="hover:text-[#fff444] active:text-yellow-600">NOMINEES</Link>
        <Link to="/vote" className="hover:text-[#fff444] active:text-yellow-600">VOTE</Link>
        <Link to="/about" className="hover:text-[#fff444] active:text-yellow-600">ABOUT</Link>
      </div>
    </nav>
  );
}
