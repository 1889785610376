export default function WinnerDisplay() {
  const winners = {
    bestSoloDev: [
    'LocalThunk (Balatro)'],
    bestSmallTeam: [
	'Calligram Studio (Phoenix Springs)'],
    bestNewStudio: [
    'Calligram Studio (Phoenix Springs)'],
    bestEarlyAccess: [
    'Hades 2'],
    bestSelfPublished: [
    'UFO 50'],
    bestGameplay: [
    'Cryptmaster'],
    bestNarrative: [
    'Mouthwashing'],
    bestArtDirection: [
    'Phoenix Springs'],
	bestSong: [
	'Pantry (Cryptmaster)'],
    bestOriginalScore: [
    'Phoenix Springs by: Alexander Smith'],
    bestAudioDesign: [
    'Mouthwashing'],
    bestWorldBuilding: [
    'Mouthwashing'],
    bestCameraWork: [
    'Harold Halibut'],
    bestPerformance: [
    'Alex Anderson Crow - Iris (Phoenix Springs)'],
    mostImpactful: [
    '1000xResist'],
    mostInnovative: [
    'Cryptmaster'],
    bestOngoing: [
    'Stardew Valley'],
    bestCommunitySupport: [
    'Dwarf Fortress'],
    bestItchioGame: [
    'Nightmare Kart'],
    bestAction: [
    'Another Crab\'s Treasure'],
    bestAdventure: [
    'Judero'],
    bestRPG: [
    'Like a Dragon: Infinite Wealth'],
    bestHorror: [
    'Mouthwashing'],
    bestPlatformer: [
    'UFO 50'],
    bestFPS: [
    'I Am Your Beast'],
    bestSimStrategy: [
    'Tactical Breach Wizards'],
    bestFighting: [
    'TEKKEN 8'],
    bestMultiplayer: [
    'Webfishing'],
    bestRemakeRerelease: [
    'Silent Hill 2'],
    bestDLC: [
    'Elden Ring: Shadow of the Erdtree'],
    bestAAA: [
    'Indiana Jones and the Great Circle'],
    bestIndie: [
    '1000xResist'],
    bestDebutIndie: [
    'Balatro'],
    mostAnticipated: [
    'Deltarune Chapter 3'],
    gameOfTheYear: [
    '1000xResist']
  };

  return (
    <div className="max-w-5xl mx-auto space-y-6 p-6">
      <div className="space-y-8">
        <DisplayField
          label="Best Solo Developer"          
          values={winners.bestSoloDev}
        />
        
        <DisplayField
          label="Best Small Developer Team (less than 15)"          
          values={winners.bestSmallTeam}
        />
        
        <DisplayField
          label="Best New Studio"          
          values={winners.bestNewStudio}
        />
        
        <DisplayField
          label="Best Early Access"          
          values={winners.bestEarlyAccess}
        />
        
        <DisplayField
          label="Best Self Published"          
          values={winners.bestSelfPublished}
        />
        
        <DisplayField
          label="Best Gameplay"          
          values={winners.bestGameplay}
        />
        
        <DisplayField
          label="Best Narrative"          
          values={winners.bestNarrative}
        />
        
        <DisplayField
          label="Best Art Direction"          
          values={winners.bestArtDirection}
        />
        
        <DisplayField
          label="Best Original Score"          
          values={winners.bestOriginalScore}
        />
        
        <DisplayField
          label="Best Song"          
          values={winners.bestSong}
        />
        
        <DisplayField
          label="Best Audio Design"          
          values={winners.bestAudioDesign}
        />
        
        <DisplayField
          label="Best World Building"          
          values={winners.bestWorldBuilding}
        />
        
        <DisplayField
          label="Best Camera Work"          
          values={winners.bestCameraWork}
        />
        
        <DisplayField
          label="Best Performance"          
          values={winners.bestPerformance}
        />
        
        <DisplayField
          label="Most Impactful"          
          values={winners.mostImpactful}
        />
        
        <DisplayField
          label="Most Innovative"          
          values={winners.mostInnovative}
        />
        
        <DisplayField
          label="Best Ongoing"          
          values={winners.bestOngoing}
        />
        
        <DisplayField
          label="Best Community Support"          
          values={winners.bestCommunitySupport}
        />
        
        <DisplayField
          label="Best Itch.io Game"          
          values={winners.bestItchioGame}
        />
        
        <DisplayField
          label="Best Action"          
          values={winners.bestAction}
        />
        
        <DisplayField
          label="Best Adventure"          
          values={winners.bestAdventure}
        />
        
        <DisplayField
          label="Best RPG"          
          values={winners.bestRPG}
        />
        
        <DisplayField
          label="Best Horror"          
          values={winners.bestHorror}
        />
        
        <DisplayField
          label="Best Platformer"          
          values={winners.bestPlatformer}
        />
        
        <DisplayField
          label="Best FPS"          
          values={winners.bestFPS}
        />
        
        <DisplayField
          label="Best Sim/Strategy"          
          values={winners.bestSimStrategy}
        />
        
        <DisplayField
          label="Best Fighting"          
          values={winners.bestFighting}
        />
        
        <DisplayField
          label="Best Multiplayer"          
          values={winners.bestMultiplayer}
        />
        
        <DisplayField
          label="Best Remake/Rerelease"          
          values={winners.bestRemakeRerelease}
        />
        
        <DisplayField
          label="Best DLC"          
          values={winners.bestDLC}
        />
        
        <DisplayField
          label="Best AAA"          
          values={winners.bestAAA}
        />
        
        <DisplayField
          label="Best Indie"          
          values={winners.bestIndie}
        />
        
        <DisplayField
          label="Best Debut Indie"          
          values={winners.bestDebutIndie}
        />
        
        <DisplayField
          label="Most Anticipated"          
          values={winners.mostAnticipated}
        />
        
        <DisplayField
          label="Game of the Year"          
          values={winners.gameOfTheYear}
        />
      </div>
    </div>
  );
};

const DisplayField = ({ label, values }) => (
  <div className="space-y-2">
    <h2 className="block text-2xl font-medium text-[#fff444]">{label}</h2>
    <div className="space-y-2">
      {values?.map((value, index) => (
        <div key={`${label}_${value}_${index}`} className="text-lg font-medium text-white pl-4">
          {value}
        </div>
      ))}
    </div>
  </div>
);