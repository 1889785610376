import { useAppContext } from '../context/AppContext';
import TextArea from '../components/TextArea';
import ShowDate from '../components/ShowDate';

export default function Home() {
  const { sharedMessage, setSharedMessage } = useAppContext();

  return (
    <TextArea>
      <h1 className="text-4xl font-bold mb-6 text-[#fff444]" style={{ fontFamily: 'Belanosima', fontWeight: '400' }}>Welcome to Yet Another Game Awards Show</h1>
      <p className="text-3xl text-gray-100 px-6">
          YAGA is Yet Another Game Awards Show.
          <br></br><br></br>
          YAGA is for everyone who loves games. We are here to recognize and celebrate outstanding games and developers.
          <br></br><br></br>
          2024 has been an incredible year for gaming. We have been playing great new games all year long.
          <br></br><br></br>
          We had a great time revealing your choices for your favorite games and developers of the year!
		  <br></br><br></br>
		  We hope you enjoyed the show. Thank you for joining us for the 2024 Yet Another Game Awards!
		  <br></br><br></br>
		  You can see all the Winners and the full Nominees list on their pages.
        </p>
        <br></br><br></br>
        {/* <ShowDate /> */}
    </TextArea>
  );
}