import { Link } from 'react-router-dom';
import { useAppContext } from '../context/AppContext';
import CardDisplay from '../components/CardDisplay';
import TextArea from '../components/TextArea';
import NomineeList from '../components/NomineeList';
import ShowDate from '../components/ShowDate';

export default function Nominees() {
  const { sharedMessage, setSharedMessage } = useAppContext();
  const sampleCards = [
    {
      imageUrl: "judero.jpg",
      title: "Judero",
      description: "Talha and Jack Co, J. King-Spooner, Talha Kaya",
      publisher: "Talha and Jack Co"
    },
    {
      imageUrl: "1000x.jpg",
      title: "Second Card",
      description: "sunset visitor 斜陽過客",
      publisher: "Fellow Traveller"
    },
    // Add more cards as needed
  ];
  
  return (
    <TextArea>
      <h1 className="text-4xl font-bold mb-6 text-[#fff444]" style={{ fontFamily: 'Belanosima', fontWeight: '400' }}>YAGA 2024 Nominees</h1>
      <div>
        {/* <CardDisplay cards={sampleCards} columns={3} /> */}
        {/* <ShowDate /> */}
        <br></br>
        <p className="text-2xl text-gray-100 px-6" style={{ fontFamily: 'ABeeZee' }}>
        Here are the nominees for the 2024 Yet Another Game Awards, nominated by the community!
        </p>
        <br></br>
        <NomineeList />
      </div>
    </TextArea>
  );
}